<template>
  <div>
    <v-card-title>
            Majority Vote Table<v-btn color="indigo lighten-1" text to="response-table">Responses</v-btn><v-btn color="indigo lighten-1" text to="func-view">Func-View</v-btn>
            <!--<v-spacer></v-spacer>
            <v-text-field v-model="searchStr" append-icon="mdi-magnify" label="Search" single-line hide-details>
            </v-text-field>-->
    <!--</v-card-title>-->

      <!--<v-col
        cols="12"
        sm="3"
      >
        <v-btn
          icon
          color="green"
          @click="getMVData()"
        >
          <v-icon>mdi-cached</v-icon>
        </v-btn>
      </v-col>-->

      <v-btn
          icon
          color="green"
          @click="getMVData()"
        >
          <v-icon>mdi-cached</v-icon>
      </v-btn>
      <v-btn
          icon
          color="black"
          @click="getExpData()"
        >
          <v-icon>mdi-television</v-icon>
      </v-btn>
      <v-btn
          icon
          color="green"
          @click="changeExpData()"
        >
          <v-icon>mdi-television</v-icon>
      </v-btn>
      <v-btn
          icon
          color="red"
          @click="completeExpData()"
        >
          <v-icon>mdi-television</v-icon>
      </v-btn>

      <!--<v-switch
        v-model="isTest"
        label="Test"
        class="pa-2"
      ></v-switch>-->

      <v-container style="width: 500px; fontSize: 15px;" class="mr-2">
        <v-stepper non-linear class="elevation-1">
          <v-stepper-header>
              <v-stepper-step step="1" :editable="true" @click="isTest=false; isLaunch=false">Prepare</v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step step="2" :editable="true" @click="isTest=true; isLaunch=false">Simulate</v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step step="3" :editable="true" @click="isTest=false; isLaunch=true">Launch</v-stepper-step>
          </v-stepper-header>
        </v-stepper>
      </v-container>

      <v-container>
        <v-card class="elevation-0">
          <p>Select Method</p>
          <v-data-table
            v-model="selectedMethod"
            :sort-by="(!isTest && !isLaunch) ?['contCount']: ['workerNum', 'match']"
            :sort-desc="(!isTest && !isLaunch) ?[true]: [false, true]"
            :footer-props="{'items-per-page-options':[1, 3, 5, 10, -1]}"
            class="elevation-0"
            :items-per-page="1"
            :headers="(!isTest && !isLaunch)? [{'text': 'count', 'value': 'contCount'}, {'text': 'continue', 'value': 'continue'}, {'text': 'params(continue)', 'value': 'pcont'}, {'text': 'terminate', 'value': 'terminate'}, {'text': 'params(terminate)', 'value': 'pterm'}]: [{'text': 'workerNum(total)', 'value': 'workerNum'}, {'text': 'match(total)', 'value': 'match'}, {'text': 'continue', 'value': 'continue'}, {'text': 'params(continue)', 'value': 'pcont'}, {'text': 'terminate', 'value': 'terminate'}, {'text': 'params(terminate)', 'value': 'pterm'}]"
            :items="prepareTest"
            show-select
            single-select
            item-key="pcont"
          >

          </v-data-table>
          <!--<v-row>
            <v-col>
              <p>continue 1</p>
            </v-col>
            <v-col>
              <p>params 0.75</p>
            </v-col>
            <v-col>
              <p>terminate 1</p>
            </v-col>
            <v-col>
              <p>params 14</p>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <p>continue 1</p>
            </v-col>
            <v-col>
              <p>params 0.8</p>
            </v-col>
            <v-col>
              <p>terminate 1</p>
            </v-col>
            <v-col>
              <p>params 14</p>
            </v-col>
          </v-row>-->
        </v-card>
      </v-container>


      <v-card-text class="text-end">
        <v-tooltip bottom v-if="selectedNanotasks.length>0 && !isTest">
            <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" class="mx-3" dark color="grey darken-2" @click="$refs.additionalPost.shown=true"><v-icon>mdi-ticket-confirmation</v-icon></v-btn>
            </template>
            <span>Assign new workers</span>
        </v-tooltip>
        <v-tooltip bottom v-if="selectedNanotasks.length>0 && isTest">
            <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" class="mx-3" dark color="grey darken-2" @click="startTest()"><v-icon>mdi-ticket-confirmation</v-icon></v-btn>
            </template>
            <span>Start test</span>
        </v-tooltip>
      </v-card-text>
    </v-card-title>
      
    <v-sheet
      tile
      height="81"
      class="d-flex justify-center"
      flat
    >
      <v-btn
        icon
        class="ma-2"
        @click="prev()"
        :disabled="this.page==1"
      >
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
      <!--<v-select
        dense
        hide-details
        class="ma-1"
        label="minimum"
        readonly
      ></v-select>
      <v-select
        dense
        hide-details
        label="continuation"
        class="ma-1"
        readonly
      ></v-select>
      <v-select
        dense
        hide-details
        label="termination"
        class="ma-1"
        readonly
      ></v-select>-->
      <v-form>
        <v-container>
          <v-row>
            <v-col
              cols="12"
              md="4"
            >
              <v-text-field
                v-model.number="conditions.minimum"
                :rules="naturalNumRules"
                label="minimum"
                required
                type="number"
              ></v-text-field>
            </v-col>

            <v-col
              cols="12"
              md="4"
            >
              <v-text-field
                v-model.number="conditions.continuation"
                :rules="ratioRules"
                label="continuation"
                required
                type="number"
              ></v-text-field>
            </v-col>

            <v-col
              cols="12"
              md="4"
            >
              <v-text-field
                v-model.number="conditions.termination"
                :rules="naturalNumRules"
                label="termination"
                required
                type="number"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
      <!--<v-spacer></v-spacer>-->
      <v-btn
        icon
        class="ma-2"
        @click="next()"
      >
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
    </v-sheet>
    <v-data-table
      multi-sort
      show-select
      v-model="selectedNanotasks"
      :headers="table.headers"
      :items="table.nanotasks"
      class="elevation-1"
      item-key="ID"
      :sort-by="isTest? ['reference']: ['isContinue', 'ratio']"
      :sort-desc="isTest? [true]: [true, false]"
      @toggle-select-all="selectAllToggle"
    >
      <template v-slot:item.data-table-select="{ item, isSelected, select }">
        <v-simple-checkbox
          :value="isSelected"
          :readonly="isTest? (item.reference === null): item.disabled"
          :disabled="isTest? (item.reference === null): item.disabled"
          @input="select($event)"
          :ripple="false"
        ></v-simple-checkbox>
      </template>

      <template v-for="i in Array.from({length: maxWorkers}, (v, k) => k)" v-slot:[`item.worker${i}`]="{ item }">
          <v-simple-table dense :key="i.id">
              <template v-slot:default>
                  <tbody>
                      <tr v-for="(value, key) in item[`worker${i}`]" :key="key">
                          <td style="width:100px"><b>{{ key }}</b></td>
                          <!--<td style="word-break:break-all" v-bind:class="{'underline-green': `${ item[`class${i}`] === 0 }`, 'underline-yellow': `${ item[`class${i}`] === 1 }` }">{{ value }}</td>-->
                          <td style="word-break:break-all" v-if="item[`class${i}`] === 0" class="underline-blue">{{ value }}</td>
                          <td v-else style="word-break:break-all">{{ value }}</td>
                      </tr>
                  </tbody>
              </template>
          </v-simple-table>
      </template>

      <template v-slot:item.result="{ item }">
        <!--<v-chip
          :color="getColor(item)"
          dark
        >
          {{ item.result }}
        </v-chip>-->
        <v-simple-table dense>
            <template v-slot:default>
                <tbody>
                    <tr v-for="(value, key) in item.result" :key="key">
                        <td style="width:100px"><b>{{ key }}</b></td>
                        <v-chip
                          :color="getColor(item)"
                          dark
                        >
                        <td style="word-break:break-all">{{ value }}</td>
                        </v-chip>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>
      </template>

      <template v-slot:item.reference="{ item }">
        <!--<v-chip
          :color="getColor(item)"
          dark
        >
          {{ item.result }}
        </v-chip>-->
        <v-simple-table dense>
            <template v-slot:default>
                <tbody>
                    <tr v-for="(value, key) in item.reference" :key="key">
                        <td style="width:100px"><b>{{ key }}</b></td>
                        <td style="word-break:break-all">{{ value }}</td>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>
      </template>

      <!--<template v-slot:item.actions="{ item }">-->
        <!--<v-btn
          href="https://github.com/vuetifyjs/vuetify/releases/latest"
          target="_blank"
          text
          :disabled="getColor(item) === 'green'"
        >-->
          <!--<span class="mr-2">Latest Release</span>-->
         <!-- <v-icon>mdi-open-in-new</v-icon>
        </v-btn>
      </template>-->
      
    </v-data-table>
    <tutti-dialog ref="additionalPost" title="Post selected Nanotasks" maxWidth="350"
        :actions="[
            { label: 'Post', color: 'error', test: true , onclick: updateNumAssignable},
            { label: 'Cancel', color: 'grey darken-1', text: true }
        ]">
        <template v-slot:body>
            Are you sure you wish to delete the selected nanotasks? This operation cannot be undone.
        </template>
    </tutti-dialog>
  </div>
</template>

<script>
import { stringifyUnixTime } from '@/lib/utils'
import TuttiDialog from '@/components/ui/TuttiDialog'

  export default {
    data () {
      return {
        disabledCount: 0,
        headers: [
          {
            text: 'Nanotask ID',
            align: 'start',
            sortable: false,
            value: 'ID',
          },
          { text: 'Result', value: 'result' , sortable: false},
          { text: 'Ratio', value: 'ratio'},
          { text: 'Worker1', value: 'worker1' , sortable: false},
          { text: 'Worker2', value: 'worker2' , sortable: false},
          { text: 'Worker3', value: 'worker3' , sortable: false},
          { text: 'Worker4', value: 'worker4' , sortable: false},
          { text: 'Worker5', value: 'worker5' , sortable: false},
          //{ text: 'Actions', value: 'actions', sortable: false },
        ],
        homeheaders: [
          { text: 'Assign', align: 'start', value: 'assign'},
          { text: 'Nanotask ID', value: 'ID'},
          { text: 'Continue', value: 'isContinue'},
          { text: 'Ratio', value: 'ratio'},
          { text: 'Result', value: 'result' , sortable: false},
          { text: 'Label', value: 'reference'},
        ],
        testheaders: [
          { text: 'Assign', align: 'start', value: 'assign'},
          { text: 'Nanotask ID', value: 'ID'},
          { text: 'Match', value: 'match'},
          { text: 'WorkersNum', value: 'workernum'},
          //{ text: 'Ratio', value: 'ratio'},
          { text: 'Result', value: 'result' , sortable: false},
          { text: 'Label', value: 'reference'},
        ],
        workerheaders: [
          { text: 'Assign', align: 'start', value: 'assign'},
          { text: 'Nanotask ID', value: 'ID'},
          { text: 'Worker1', value: 'worker1' , sortable: false},
          { text: 'Worker2', value: 'worker2' , sortable: false},
          { text: 'Worker3', value: 'worker3' , sortable: false},
          //{ text: 'Worker4', value: 'worker4' , sortable: false},
          //{ text: 'Worker5', value: 'worker5' , sortable: false},
        ],
        table: {
          headers: [
            { text: 'Assign', align: 'start', value: 'assign'},
            { text: 'Nanotask ID', value: 'ID'},
            { text: 'Result', value: 'result' , sortable: false},
            { text: 'Ratio', value: 'ratio'},
            { text: 'Worker1', value: 'worker1' , sortable: false},
            { text: 'Worker2', value: 'worker2' , sortable: false},
            { text: 'Worker3', value: 'worker3' , sortable: false},
            { text: 'Worker4', value: 'worker4' , sortable: false},
            { text: 'Worker5', value: 'worker5' , sortable: false},
            //{ text: 'Actions', value: 'actions', sortable: false },
          ],
          nanotasks: []
        },
        nanotasks: [
          {
            assign: "1/1",
            ID: 'NT:00001403',
            result: 159,
            ratio: 1,
            worker1: ["foooooooooooooooo","booooooooooooooooooooooo"],
            worker2: "foo",
            worker3: "bar",
            worker4: "foo",
          },
          {
            assign: "1/1",
            ID: 'NT:00001402',
            result: 237,
            ratio: 1,
            worker1: 1,
            worker2: 1,
            worker3: 1,
            worker4: 1,
          },
          {
            assign: "1/1",
            ID: 'NT:00001401',
            result: 262,
            ratio: 1,
            worker1: 1,
            worker2: 0,
            worker3: 1,
            worker4: 0,
          },
          {
            assign: "1/1",
            ID: 'NT:00001400',
            result: 305,
            ratio: 1,
            worker1: 0,
            worker2: 0,
            worker3: 1,
            worker4: 0,
          },
          {
            assign: "1/1",
            ID: 'NT:00001399',
            result: 356,
            ratio: 1,
            worker1: "bar",
            worker2: "bar",
            worker3: "bar",
            worker4: "bar",
            worker5: "foo",
          },
          {
            assign: "1/1",
            ID: 'NT:00001398',
            result: 375,
            ratio: 1,
            worker1: 0,
            worker2: 0,
            worker3: 0,
            worker4: 0,
          },
          {
            assign: "1/1",
            ID: 'NT:00001397',
            result: 392,
            ratio: 1,
            worker1: 1,
            worker2: 1,
            worker3: 1,
            worker4: 1,
          },
          {
            assign: "1/1",
            ID: 'NT:00001396',
            result: 408,
            ratio: 1,
            worker1: 1,
            worker2: 1,
      
          },
          {
            assign: "1/1",
            ID: 'NT:00001395',
            result: 452,
            ratio: 1,
            worker1: 1,
            worker2: 1,
            worker3: 0,
            worker4: 0,
            worker5: 1,
          },
          {
            assign: "1/1",
            ID: 'NT:00001394',
            result: 518,
            ratio: 1,
            worker1: 2,
            worker2: 1,
            worker3: 4,
            worker4: 3,
          },
        ],
        nanotaskIdSet: new Set(),
        workerIdSet: new Set(),
        selectedNanotasks: [],
        ProjectName: null,
        templateName: null,
        page: 1,
        maxWorkers: 100,
        conditions: {
          minimum: 3,
          continuation: 0.75,
          termination: 8
        },
        naturalNumRules: [
          v => !!v || 'Name is required',
          v => /^[0-9]+$/.test(v) || 'natural number only',
        ],
        ratioRules: [
          v => !!v || 'Name is required',
          v => /^[0]\.[0-9]+$/.test(v) || '0 < number < 1 only',
        ],
        isContinued: null,
        showNum: 3,
        isTest: false,
        prepareTest: [],
        selectedMethod: undefined,
        isLaunch: false,
        testResultData: {},

        expCount: 3,
        expSelectedIds: {},
        expTestResult: null,
        isAutoExp: false,
        expId: 0,
        headRandInt: [0,0,0,0,0,0],
        tailRandInt: [0,0,0,0,0,0]
      }
    },
    props: ["duct", "prjName", "tmplName"],
    components: {
        TuttiDialog
    },
    created() {
        //const fs = require('fs');
        //var obbbbjjjj = { id: 1, name: "js-primer", bio: null };
        //var dataaaaa = JSON.stringify(obbbbjjjj);
        //console.log(fs);
        //fs.writeFile("result.json", dataaaaa, (err) => {
          //if (err) throw err;
          //console.log('正常に書き込みが完了しました');
        //});
        const self = this;
        this.table.headers = this.homeheaders;
        this.table.nanotasks.map(item => {
          if (item.disabled) self.disabledCount += 1
        })
        this.duct.invokeOrWaitForOpen(() => {
            this.duct.eventListeners.resource.on("getMajorityVoteData", {
                success: (data) => {
                  console.log(data);
                  this.table.nanotasks = [];
                  for (let [key, val] of Object.entries(data["MVData"])) {
                    const tval = {}
                    tval["ID"] = key;
                    tval["assign"] = val["Answers"].length + "/" + val["NumAssignable"];

                    if (val["Eval"]["terminate"]) { tval["isContinue"] = false; tval["disabled"] = true; }
                    else { tval["isContinue"] = val["Eval"]["continuation"]; tval["disabled"] = !val["Eval"]["continuation"]; }
                    
                    let workerNum = val["Answers"].length;
                    let count = 0;
                    for (let ii in Array.from({length: workerNum}, (v, k) => k) ) {
                      let i = parseInt(ii);
                      tval["worker"+(i+1)] = val["Answers"][i]["ANSWER"];
                      tval["class"+(i+1)] = val["Answers"][i]["CLASS"];
                      if (val["Answers"][i]["CLASS"] === 0) {
                        tval["result"] = val["Answers"][i]["RESULT"];
                        count++;
                      }
                    }
                    //console.log(count);
                    //Math.round(max / i * 10000) / 10000;
                    tval["ratio"] = Math.round(count / workerNum *10000) / 10000;
                    tval["reference"] = val["ReferenceAnswers"]
                    tval["matchworkers"] = null;
                    tval["match"] = null;
                    tval["workernum"] = null;
                    //console.log(tval);
                    this.table.nanotasks.push(tval);
                  }
                  //const tval = {}
                  //tvals[""]

                  //if(this.isTest) this.startTest();
                }
            });
            this.duct.eventListeners.resource.on("getResponsesForTemplate", {
                success: (data) => {
                  //console.log(data["ProjectName"]);
                  //console.log(data["TemplateName"]);
                  //console.log(data["NumAssignables"]);
                  //console.log(data["MVFunc"]);
                  //console.log(data["ModifiedData"]);
                  console.log(data["Responses"]);

                  this.ProjectName = data["ProjectName"];
                  this.TemplateName = data["TemplateName"];
                  this.nanotaskIdSet.clear();
                  this.workerIdSet.clear();
                  this.table.nanotasks = [];
                  const rows = data["Responses"].map(
                    (row) => (
                      Object.assign(row, {
                        Timestamp: stringifyUnixTime( row.Timestamp ) || ""
                          })
                      )
                  );
                  console.log(data["Responses"]);
                  rows.forEach(row => {
                    //const ansKeys = Object.keys(row.Answers);
                      //ansKeys.forEach(ansKey => {
                        //console.log("key:" + ansKey + " ans:" + row.Answers[ansKey]);
                      //})
                      this.nanotaskIdSet.add(row.NanotaskId);
                      this.workerIdSet.add(row.WorkerId);
                      //console.log(row);
                  })
                  //console.log(this.table.Rows[0]);
                  //const keys = Object.keys(this.table.Rows[0].Answers);
                  //keys.forEach(key => {
                    //console.log("key:" + key + " ans:" + this.table.Rows[0].Answers[key]);
                  //})
                  this.nanotaskIdSet.forEach(nanotaskId => {
                    const Row = {};
                    Row["ID"] = nanotaskId;
                    Row["result"] = null;
                    Row["ratio"] = null;
                    let i = 0, f = false;
                    this.workerIdSet.forEach(workerId => {
                      rows.forEach( row => {
                        if(row.NanotaskId === nanotaskId && row.WorkerId === workerId) {
                          //Row["worker"+(i+1)] = row.Answers[Object.keys(row.Answers)[0]];
                          console.log(row.Answers);
                          Row["worker"+(i+1)] = row.Answers;
                              f = true;
                            }
                        })
                        if(f && i < this.maxWorkers) {
                          //this.table.headers[4+i].text = "worker"+(i+1);
                          //this.table.headers[4+i].value = "worker"+(i+1);
                          i++; f = false;
                        }
                    })
                    Row["assign"] = i + "/" + data["NumAssignables"][nanotaskId];
                    this.table.nanotasks.push(Row);
                  })
                  //console.log(this.table.nanotasks);


                  this.isContinued = data["MVFunc"]["eval"]
                  //console.log("MVF")
                  //console.log(this.isContinued)
                  this.table.nanotasks.forEach(nanotask => {
                      nanotask["disabled"] = !this.isContinued[nanotask["ID"]]

                  });
                }
            });
            this.duct.eventListeners.resource.on("testMajorityVoteFunc", {
              success: (data) => {
                console.log("test");
                console.log(data["TestResult"]);

                console.log(data["MethodEvalData"]);
                this.expTestResult = data["TestResult"];

                for (let i in this.prepareTest) {
                  this.prepareTest[i]["workerNum"] = data["MethodEvalData"]["worker_num"][this.prepareTest[i]["pcont"]];
                  this.prepareTest[i]["match"] = data["MethodEvalData"]["match_num"][this.prepareTest[i]["pcont"]] + "/" + Object.keys(data["TestResult"]).length;
                }

                for( let i in this.table.nanotasks) {
                  if (this.table.nanotasks[i]["ID"] in data["TestResult"]) {
                    this.table.nanotasks[i]["match"] = data["TestResult"][this.table.nanotasks[i]["ID"]]["Match"][0.75];
                    this.table.nanotasks[i]["workernum"] = data["TestResult"][this.table.nanotasks[i]["ID"]]["WorkerNum"][0.75];
                  }
                }

                /*for(let i in this.prepareTest) {
                  this.prepareTest[i]["workerNum"] += data["TestResult"]["WorkerNum"][this.prepareTest[i]["pcont"]];
                  if(data["TestResult"]["Match"][this.prepareTest[i]["pcont"]]) this.prepareTest[i]["match"] += 1;
                }
                //this.prepareTest[0]["workerNum"] = this.prepareTest[0]["workerNum"] + 0

                for(let i in this.table.nanotasks) {
                  if(this.table.nanotasks[i]["ID"] === data["TestResult"]["NanotaskId"]) {
                    this.table.nanotasks[i]["match"] = data["TestResult"]["Match"][0.75];
                    this.table.nanotasks[i]["workernum"] = data["TestResult"]["WorkerNum"][0.75];
                    this.table.nanotasks[i]["result"] = data["TestResult"]["Result"][0.75];
                    //console.log("BAAA");
                  }
                  //console.log("AAA");
                }*/
                //this.testResultData[data["TestResult"]["NanotaskId"]] = data["TestResult"]; 
              }
            })
            this.duct.eventListeners.resource.on("prepareMajorityVoteData", {
              success: (data) => {
                console.log(data);
                this.prepareTest = []
                for (let key in data["PrepareData"] ) {
                  console.log(key);
                  this.prepareTest.push(
                    { "contCount": data["PrepareData"][key]["count"], "continue": 1, "pcont": key, "terminate": 1, "pterm": 14 }
                  )
                }
                if (this.selectedMethod) {
                  for(let i in this.table.nanotasks) {
                    console.log(this.selectedMethod[0]["pcont"]);
                    console.log(data["PrepareData"][String(this.selectedMethod[0]["pcont"])]["continue"][this.table.nanotasks[i]["ID"]]);
                    this.table.nanotasks[i]["isContinue"] = data["PrepareData"][String(this.selectedMethod[0]["pcont"])]["continue"][this.table.nanotasks[i]["ID"]];
                    this.table.nanotasks[i]["disabled"] = !this.table.nanotasks[i]["isContinue"];
                    if(!this.table.nanotasks[i]["isContinue"]) { this.table.nanotasks[i]["reference"] = this.table.nanotasks[i]["result"]; }
                    else { this.table.nanotasks[i]["reference"] = null; }
                  }
                }
              }
            });
            this.duct.eventListeners.resource.on("getExpData", {
              success: (data) => {

                /*const selectedIdList = []
                for (let index in this.selectedNanotasks) {
                  selectedIdList.push(this.selectedNanotasks[index]["ID"]);
                }
                console.log(this.expCount);*/

                console.log(data);
                this.table.nanotasks = [];
                for (let [key, val] of Object.entries(data["MVData"])) {

                  /*if(this.expCount !== 0) {
                    let expFlag = false;
                    for(let i in selectedIdList) {
                      if(selectedIdList[i] === key) {
                        expFlag = true;
                        break;
                      }
                    }
                    if(!expFlag) {
                      continue;
                    }
                  }*/
                  

                  const tval = {}
                  tval["ID"] = key;
                  tval["assign"] = val["Answers"].length + "/" + val["NumAssignable"];

                  if (val["Eval"]["terminate"]) { tval["isContinue"] = false; tval["disabled"] = true; }
                  else { tval["isContinue"] = val["Eval"]["continuation"]; tval["disabled"] = !val["Eval"]["continuation"]; }
                  
                  let workerNum = val["Answers"].length;
                  let count = 0;
                  let count2 = 0;
                  let count3 = 0;
                  let i;
                  for (let ii in Array.from({length: workerNum}, (v, k) => k) ) {
                    i = parseInt(ii);

                    /*if ((this.expCount+3) <= i) {
                      break;
                    }*/

                    tval["worker"+(i+1)] = val["Answers"][i]["ANSWER"];
                    tval["class"+(i+1)] = val["Answers"][i]["CLASS"];
                    if (val["Answers"][i]["CLASS"] === 0) {
                      tval["result"] = val["Answers"][i]["RESULT"];
                      count++;
                    }
                    if (val["Answers"][i]["CLASS"] === 1) {
                      count2++;
                    }
                    if (val["Answers"][i]["CLASS"] === 2) {
                      count3++;
                    }
                  }



                  //console.log(count);
                  //Math.round(max / i * 10000) / 10000;
                  tval["ratio"] = String( Math.round(count / workerNum *100) / 100 ) + " / " + String( Math.round(count2 / workerNum *100) / 100 ) + " / " + String( Math.round(count3 / workerNum *100) / 100 );
                  tval["reference"] = val["ReferenceAnswers"]
                  //tval["reference"] = (( Math.round(count / workerNum *100) / 100 ) > 0.6) ? val["RequesterLabel"]: null;
                  tval["matchworkers"] = null;
                  tval["match"] = null;
                  tval["workernum"] = null;

                  tval["requesterlabel"] = val["RequesterLabel"]; //
                  //console.log(tval);
                  this.table.nanotasks.push(tval);
                }
                //this.expCount++;

                this.prepareTest = []
                for (let key in data["PrepareData"] ) {
                  console.log(key);
                  this.prepareTest.push(
                    { "contCount": data["PrepareData"][key]["count"], "continue": 1, "pcont": key, "terminate": 1, "pterm": 14 }
                  )
                }
                if (this.selectedMethod) {
                  for(let i in this.table.nanotasks) {
                    console.log(this.selectedMethod[0]["pcont"]);
                    console.log(data["PrepareData"][String(this.selectedMethod[0]["pcont"])]["continue"][this.table.nanotasks[i]["ID"]]);
                    this.table.nanotasks[i]["isContinue"] = data["PrepareData"][String(this.selectedMethod[0]["pcont"])]["continue"][this.table.nanotasks[i]["ID"]];
                    this.table.nanotasks[i]["disabled"] = !this.table.nanotasks[i]["isContinue"];
                    if(!this.table.nanotasks[i]["isContinue"]) { this.table.nanotasks[i]["reference"] = this.table.nanotasks[i]["result"]; }
                    else { this.table.nanotasks[i]["reference"] = null; }
                  }
                }
                //
                let expValidate = {};
                let expValidateCount = 0;
                for(let i in this.table.nanotasks) {
                  if(!this.table.nanotasks[i]["isContinue"]) {
                    expValidate[this.table.nanotasks[i]["ID"]] = {"RequesterLabel": this.table.nanotasks[i]["requesterlabel"]["choice"], "Label": this.table.nanotasks[i]["reference"]["choice"]};
                    if ( this.table.nanotasks[i]["requesterlabel"]["choice"] !== this.table.nanotasks[i]["reference"]["choice"] ) {
                      expValidateCount += 1;
                    }
                  }
                }
                console.log("expValidate");
                console.log(expValidate);
                console.log(expValidateCount);
                console.log(Object.keys(expValidate).length);
                if(this.isAutoExp) {
                  this.expCount++;
                  this.completeExpData();
                }
                if(this.expCount == 13) {
                  for(let i in this.table.nanotasks) {
                    this.table.nanotasks[i]["isContinue"] = data["PrepareData"][String(this.selectedMethod[0]["pcont"])]["continue"][this.table.nanotasks[i]["ID"]];
                    //this.table.nanotasks[i]["disabled"] = !this.table.nanotasks[i]["isContinue"];
                    //this.table.nanotasks[i]["reference"] = this.table.nanotasks[i]["result"]; 
                  }
                  console.log("expFile");
                  console.log(data["expFile"]);
                  console.log(data["isInit"]);
                }
              }
            })
        });
    },
    watch: {
      selectedMethod(val) {
        console.log(val);
      },
      //selectedNanotasks (val) {
        //console.log("selectedNanotasks")
        //console.log(val);
      //}
      //tmplName (val) {
        //console.log("tmplName!!!");
        //console.log(val);
      //}
      tmplName() {
        this.getMVData();
      },
      isTest(val) {
        if(val) {
          console.log(this.table.headers);
          this.table.headers = this.testheaders;
          //this.table.headers = this.homeheaders;
          console.log(this.table.headers);
        } else {
          console.log(this.table.headers);
          this.table.headers = this.homeheaders;
          //this.table.headers = this.homeheaders;
          console.log(this.table.headers);
        }
      }
    },
    computed: {
      selectedNanotaskIds() {
          //console.log(this.selectedNanotasks);
          return this.selectedNanotasks.map((x) => (x.ID));
      }
    },
    methods: {
      /*testMethodResult() {
        console.log(this.testResultData);
        console.log("this.table.nanotasks1");
        console.log(this.table.nanotasks);
        if(this.selectedMethod[0]) {
          for(let i in this.table.nanotasks) {
            let data = this.testResultData[this.table.nanotasks[i]["ID"]]
            console.log("data");
            console.log(data);
            this.table.nanotasks[i]["match"] = data["Match"][String(this.selectedMethod[0]["pcont"])];
            this.table.nanotasks[i]["workernum"] = data["WorkerNum"][String(this.selectedMethod[0]["pcont"])];
            this.table.nanotasks[i]["result"] = data["Result"][String(this.selectedMethod[0]["pcont"])];
            console.log("this.table.nanotasks2");
            console.log(this.table.nanotasks);
            console.log("AAA");
          }
        }
      },*/
      selectAllToggle(props) {
        let count = 0;
        props.items.forEach(item => {
          if (this.isTest) {
            if ( item.reference ==null) count++;
          } 
          else {
            if ( item.disabled) count++;
          }
          //if( (this.isTest && (item.reference === null))  item.disabled) count++;
        });
        if(this.selectedNanotasks.length != props.items.length - count) {
          this.selectedNanotasks = [];
          const self = this;
          props.items.forEach(item => {
            /*if((this.isTest && (item.reference !== null)) || !item.disabled) {
              self.selectedNanotasks.push(item);
            }*/
            if (this.isTest) {
              if ( item.reference !== null) self.selectedNanotasks.push(item);
            } 
            else {
              if ( !item.disabled ) self.selectedNanotasks.push(item);
            }
          });
        } else this.selectedNanotasks = [];
        console.log(this.selectedNanotasks);
      },
      prev() {
        this.page--;
        console.log("prev:" +this.page);
        const page = this.page;
        const showNum = this.showNum;

        if (this.page == 1) {
          if (!this.isTest) this.table.headers = this.homeheaders;
          else this.table.headers = this.testheaders;
        }
        else {
          for ( let i = 0; i < showNum; i++ ) {
            let j = i + showNum*(page-1)-showNum;
            //this.table.headers[4+i].text = "worker"+(j+1);
            //this.table.headers[4+i].value = "worker"+(j+1);
            this.workerheaders[2+i].text = "worker"+(j+1);
            this.workerheaders[2+i].value = "worker"+(j+1);
            this.table.headers = this.workerheaders;
          }
        }
      },
      next() {
        this.page++;
        console.log("next:" +this.page);
        const page = this.page;
        const showNum = this.showNum;
        for ( let i = 0; i < showNum; i++ ) {
          let j = i + showNum*(page-1)-showNum;
          //this.table.headers[4+i].text = "worker"+(j+1);
          //this.table.headers[4+i].value = "worker"+(j+1);
          this.workerheaders[2+i].text = "worker"+(j+1);
          this.workerheaders[2+i].value = "worker"+(j+1);
          this.table.headers = this.workerheaders;
        }
      },
      getMVData() {
        if(!this.isTest) this.duct.controllers.resource.getMajorityVoteData(this.prjName, this.tmplName);
        if(!this.isTest && !this.isLaunch) this.duct.controllers.resource.prepareMajorityVoteData(this.prjName, this.tmplName);
        //if(this.isTest) this.testMethodResult();
      },
      startTest() {
        let Vars = { "minimum": this.conditions.minimum, "continue": this.conditions.continuation, "terminate": this.conditions.termination };
        this.testResultData = [];
        for(let i in this.prepareTest) {
          this.prepareTest[i]["workerNum"] = 0;
          this.prepareTest[i]["match"] = 0;
        }
        let selectedAnswerPerId = {};
        let selecetedLabelPerId = {};
        for (let index in this.selectedNanotasks) {
          console.log(Vars);
          console.log(this.selectedNanotasks[index]);
          //this.duct.controllers.resource.testMajorityVoteFunc(this.prjName, this.tmplName, Vars, this.selectedNanotasks[index]);
          selectedAnswerPerId[this.selectedNanotasks[index]["ID"]] = []
          let i = 0
          while(i < 10000000){
            
            if(("worker"+(i+1)) in this.selectedNanotasks[index]) {
              selectedAnswerPerId[this.selectedNanotasks[index]["ID"]].push(this.selectedNanotasks[index]["worker"+(i+1)])
              i++;
            } else {
              break;
            }
          }
          selecetedLabelPerId[this.selectedNanotasks[index]["ID"]] = this.selectedNanotasks[index]["reference"]
        }
        console.log("selectedAnswerPerId");
        console.log(selectedAnswerPerId);
        this.duct.controllers.resource.testMajorityVoteFunc(this.prjName, this.tmplName, Vars, selectedAnswerPerId, selecetedLabelPerId);
      },
      getExpData() {
        console.log("Hello");
        for (let index in this.selectedNanotasks) {
          if (!(this.selectedNanotasks[index]["ID"] in this.expSelectedIds)) {
            this.expSelectedIds[this.selectedNanotasks[index]["ID"]] = 3
          }
          this.expSelectedIds[this.selectedNanotasks[index]["ID"]] += 1;
        }
        console.log(this.expSelectedIds);
        console.log(this.expCount);
        this.duct.controllers.resource.getExpData(this.prjName, this.tmplName, this.expCount, this.expSelectedIds, 0, [0,0,0,0,0,0], [0,0,0,0,0,0]);
      },
      changeExpData() {
        if (this.selectedMethod) {
          for( let i in this.table.nanotasks) {
            if (this.table.nanotasks[i]["ID"] in this.expTestResult) {
              this.table.nanotasks[i]["match"] = this.expTestResult[this.table.nanotasks[i]["ID"]]["Match"][String(this.selectedMethod[0]["pcont"])];
              this.table.nanotasks[i]["workernum"] = this.expTestResult[this.table.nanotasks[i]["ID"]]["WorkerNum"][String(this.selectedMethod[0]["pcont"])];
            }
          }
        }
      },
      completeExpData() {
        console.log("Hello");
        this.isAutoExp = true;
        for (let index in this.table.nanotasks) {
          if (!(this.table.nanotasks[index]["ID"] in this.expSelectedIds)) {
            this.expSelectedIds[this.table.nanotasks[index]["ID"]] = 3
          }
          if ( this.table.nanotasks[index]["isContinue"] ) {
            this.expSelectedIds[this.table.nanotasks[index]["ID"]] += 1;
          }
        }
        console.log(this.expSelectedIds);
        console.log(this.expCount);
        if ( this.expCount < 13 ) {
          this.duct.controllers.resource.getExpData(this.prjName, this.tmplName, this.expCount, this.expSelectedIds, this.expId, this.headRandInt, this.tailRandInt);
        } else {
          if(this.expCount == 13) {
            for(let i in this.table.nanotasks) {
              //this.table.nanotasks[i]["isContinue"] = data["PrepareData"][String(this.selectedMethod[0]["pcont"])]["continue"][this.table.nanotasks[i]["ID"]];
              if (Number((this.table.nanotasks[i]["ratio"]).split("/")[0]) > 0.6) {
                this.table.nanotasks[i]["disabled"] = !this.table.nanotasks[i]["isContinue"];
                this.table.nanotasks[i]["reference"] = this.table.nanotasks[i]["result"];
              } 
            }
            if(this.expId < 250) {
              this.expId++;
              this.expCount = 2;
              this.table.nanotasks = [];
              this.expSelectedIds = {};
              this.headRandInt = [Math.floor(Math.random() * (7 - 0) + 0),Math.floor(Math.random() * (7 - 0) + 0),Math.floor(Math.random() * (7 - 0) + 0),Math.floor(Math.random() * (7 - 0) + 0),Math.floor(Math.random() * (7 - 0) + 0),Math.floor(Math.random() * (7 - 0) + 0)];
              this.tailRandInt = [Math.floor(Math.random() * (13 - 7) + 7),Math.floor(Math.random() * (13 - 7) + 7),Math.floor(Math.random() * (13 - 7) + 7),Math.floor(Math.random() * (13 - 7) + 7),Math.floor(Math.random() * (13 - 7) + 7),Math.floor(Math.random() * (13 - 7) + 7)];
              this.duct.controllers.resource.getExpData(this.prjName, this.tmplName, this.expCount, this.expSelectedIds, this.expId, this.headRandInt, this.tailRandInt);
            }
            //console.log("expFile");
            //console.log(data["expFile"]);
            //console.log(data["isInit"]);
          }
        }
      },
      getColor (item) {
        //const dic = []; const set = new Set(); const map = new Map();
        /*let i;
        for (i = 0; i < 5; i++ ) {
          let t = item["worker"+i];
          if(t === undefined) break;
          dic[i] = t; 
          set.add(t);
        }*/
        /*let i;
        for(i = 0; i < this.maxWorkers; i++) {
          let t = item["worker"+(i+1)];
          //console.log("worker" + i + ":" + t);
          if(t === undefined) break;
          
          dic[(i+1)] = t;
          set.add(t);
          
        }
        
        set.forEach((value) => {
          
          let sum = dic.reduce((prev, cur) => { 
            if (value == cur) prev++;
            return prev;
           }, 0)

           map.set(value, sum)
        })
        let result; let max = 0;
        map.forEach((key, value) => {
          if ( max < key) {max = key; result = value;}

        })
        
        item.result = result;
        item.ratio = Math.round(max / i * 10000) / 10000;*/
        //item.ratio = Math.round(item.ratio * 1000) / 1000;
        //console.log("result:" + result + " ratio:" + item.ratio + "SetSize:" + this.workerIdSet.size + "max:" + max);
        if ( !item.isContinue ) {/*item.disable = true;*/ return 'green';}
        else if (item.ratio > 0.5) {/*item.disable = false;*/ return 'orange';}
        else {/*item.disable = false;*/ return 'red'}
      },
      updateNumAssignable() {
        for(var i in this.selectedNanotaskIds) {
            //var numAssignable;
            
            //numAssignable = this.selectedNanotasks[i]["NumAssignable"] + 1;
            console.log(this.selectedNanotaskIds[i]);
            //console.log(this.ProjectName);
            //console.log(this.TemplateName);
            console.log(this.prjName);
            console.log(this.tmplName);
            this.duct.controllers.resource.updateNanotaskNumAssignable(this.prjName, this.tmplName, this.selectedNanotaskIds[i], -1);
        }
      },
    },
  }
</script>

<style>

.underline-green {
  text-decoration: underline;
  text-decoration-color: green;
  text-decoration-thickness:0.5px;
  text-decoration-skip-ink: none;
  text-underline-offset: 7px;
}

.underline-blue {
  text-decoration: underline;
  text-decoration-color: blue;
  text-decoration-thickness:0.5px;
  text-decoration-skip-ink: none;
  text-underline-offset: 7px;
}

.underline-yellow {
  text-decoration: underline;
  text-decoration-color: yellow;
  text-decoration-thickness: 0.5px;
  text-decoration-skip-ink: none;
  text-underline-offset: 7px;
}

.underline-red {
  text-decoration: underline;
  text-decoration-color: red;
  text-decoration-thickness: 0.5px;
  text-decoration-skip-ink: none;
  text-underline-offset: 7px;
}

</style>

